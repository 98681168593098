import { useContext } from "react"
import { loop } from "../../../helpers/arrayworks.helper"
import { useParams } from "react-router-dom"
import { AlertBase } from "../../../components/ui/views/alerts"
import { IGroupsHook } from "../../../hooks/groups.hook"
import { empty } from "../../../helpers/util.helper"
import { DispositionForm } from "./form"
import { CreateButtonBlock } from "../../../components/ui/blocks"
import { DispositionDashboard } from "./dashboard"
import { DispositionContext } from "../provider"
import IComponentState, { ComponentStateDto } from "../../../interfaces/component-state.interface"
import PermissionsDataGridRowsComponent from "../../../components/permissions/datagrid-rows"

interface IDispositionComponent
{
    groups: IGroupsHook
    backButtonEvent: any
    createEvent: any
    setUpdateEvent: any
}

export const DispositionComponent = ({
    groups,
    backButtonEvent,
    createEvent,
    setUpdateEvent
}: IDispositionComponent) => {
    const { dispositionList, dispositionListState, setDispositionListState, dispositionState } = useContext(DispositionContext);
    const { gid } = useParams()
    const filteredGroups: any[] = []
    const state: IComponentState<any> = dispositionListState;
    loop(groups.groups, (v: any) => {
        if(v.decentralized === 1) {
            filteredGroups.push(v)
        }
    })

    const reload = () => setDispositionListState(ComponentStateDto);

    return (
        <div>
            { !empty(gid) && dispositionState.ready && (
                <DispositionForm
                    reload={ setUpdateEvent }
                    groups={ groups }
                    backButtonEvent={ backButtonEvent }
                />) }
            
            { empty(gid) && state.ready && (
                <PermissionsDataGridRowsComponent
                    label={
                    <AlertBase
                        text="You do not have any disposition groups."
                        type="info"
                    /> }
                    rows={ dispositionList || []}
                >
                    <DispositionDashboard
                        reloadEvent={ reload }
                        groups={ dispositionList }
                        backButtonEvent={ backButtonEvent }
                        editGroupEvent={ setUpdateEvent }
                        createButton={ 
                            <CreateButtonBlock
                                label={ "You have no Disposition Groups available." }
                                createEvent={ createEvent }
                            />
                        }
                    />
                </PermissionsDataGridRowsComponent>
            )}
        </div>
    )
}