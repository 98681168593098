import { useContext, useEffect, useState } from "react"
import { DataGrid, GridColDef, GridRowModel, GridRowModesModel } from "@mui/x-data-grid"
import { useGroupTypesHook } from "../../../hooks/groups.hook"
import { BootStrapSpinner } from "../../ui/views/spinner"
import { AlertBase, AlertYinYang } from "../../ui/views/alerts"
import { HerdGroupToolActions } from "./herd-group-tool-actions"
import { useHerdLockHook } from "../../../hooks/herd.hook"
import { ApplicationContext } from "../../../providers/application.provider"
import { toDate } from "../../../helpers/util.helper"
import { Tooltip } from "@mui/material"
import IComponentState from "../../../interfaces/component-state.interface"
import DropdownGroupStatus from "../../dropdown-group-status"
import PermissionsDataGridRowsComponent from "../../permissions/datagrid-rows"
import { filterMuiColumns } from "../../../helpers/arrayworks.helper"

interface ICalvingGroupListComponent
{
    groups: any
    groupState: IComponentState<any>
    setGroupState: any
    error: string
}

export const CalvingGroupListComponent = ({ groups, groupState, setGroupState, error }: ICalvingGroupListComponent) => {
    const [ loading, setLoading ] = useState<boolean>(false)
    const [ rowModesModel, setRowModesModel ] = useState<GridRowModesModel>({})
    //const [ dataTableTarget, setDataTableTarget ] = useState('')
    const updateAttribute = (e: any) => e

    const { setLock, setGroupId, lockState, success, setSuccess } = useHerdLockHook()
    const { toggleForm } = useContext(ApplicationContext)
    //const { groups, groupState, setGroupState, error } = useGroupsHook()

    const { groupTypes, getItemValue } = useGroupTypesHook()

    const dataTableColumns: GridColDef[] = [
        { field: 'group_type', headerName: '', width: 60, renderCell: (e) =>  <Tooltip title={getItemValue(e.row.group_type, groupTypes, 'description')}><div className={`abbr-dot color-${e.row.group_type}`}>{getItemValue(e.row.group_type, groupTypes, 'shortcode')?.substring(0,1).toUpperCase()}</div></Tooltip> },
        { field: 'status', headerName: 'Group Name / Status', flex: 2, minWidth: 200, renderCell: (e) =>  <DropdownGroupStatus row={e.row} /> },
        { field: 'season', headerName: 'Calving Season', flex: 1, headerAlign: 'center', cellClassName: 'justify-content-center', renderCell: (e) => <>{e.row.first_born_date? `${e.row.first_born_date} / ${e.row.last_born_date}` : ''}</> },
        { field: 'selected_certs', headerName: 'Certs', flex: 1, headerAlign: 'center', cellClassName: 'justify-content-center', renderCell: (e) => <>{ (e.row.selected_certs && e.row.selected_certs.length > 0)? e.row.selected_certs.join('/').toUpperCase() : e.row.herd_count === 0? null : null }</> },
        // { field: 'decentralized', headerName: 'Stored', flex: 1, description: 'This signifies your group being published to permanent storage.' },
        { field: 'herd_count', headerName: 'Count', flex: 1, headerAlign: 'center', cellClassName: 'justify-content-center', description: 'This is the number of animals in the group' },
        { field: 'created_at', headerName: 'Created', flex: 1, headerAlign: 'center', cellClassName: 'justify-content-center', renderCell: (e) => toDate(e.row.created_at) },
        { field: 'actions', headerName: 'Group Actions', headerAlign: 'right', minWidth: 230, renderCell: (e: any) => <HerdGroupToolActions {...{ ...e.row, setGroupState, setSuccess, success, setLock, setGroupId, lockState, row: e.row }} />, flex: 1}
    ];
    // Reload the groups based on turning off the toggle
    useEffect(() => {
        if(!toggleForm) {
            if(!loading) {
                setLoading(true)
            }
        }
    }, [ toggleForm ]);

    useEffect(() => {
        if(error !== '' && success.msg !== error)
            setSuccess({ success: false, msg: error })
    });

    return (
        groupState.ready? 
            <div>
                { !!success.msg && success.msg !== ''? <AlertYinYang success={success.success? success.msg : ''} error={!success.success? success.msg : ''} /> : null }

                <PermissionsDataGridRowsComponent
                    label={
                    <AlertBase
                        text="You do not have any groups yet."
                        type="info"
                    /> }
                    rows={ groups.groups || [] }
                >
                    <DataGrid
                        getRowId={(e) => e.ID}
                        //onProcessRowUpdateError={(e) => e}
                        rowModesModel={ rowModesModel }
                        onRowModesModelChange={ setRowModesModel }
                        // onCellEditStart={(e) => {
                        //     setDataTableTarget(e.field)
                        // }}
                        processRowUpdate={(e: GridRowModel) => {
                            updateAttribute(e)
                            return e
                        }}
                        rows={ groups.count > 0? groups.groups : [] }
                        columns={ filterMuiColumns(dataTableColumns, groups.groups || [], ['actions']) }
                        checkboxSelection={false}
                    />
                </PermissionsDataGridRowsComponent>
            </div>
        : <BootStrapSpinner />
    )
}