import { useContext, useEffect, useState } from "react"
import { Button } from "@mui/material"
import { InvoicesPayAll, InvoicesPayDto, InvoicesPending, WidgetCommittedHerdsDto } from "../../../services/invoice"
import { ModalContext } from "../../../providers/modal.provider"
import { CheckoutContext } from "../../../providers/checkout.provider"
import { AccountContext } from "../../../providers/account.provider"
import ButtonBack from "../../ui/buttons/button-back"
import CopyAllIcon from '@mui/icons-material/CopyAll';
import __ from "../../../services/translator"
import "./styles.scss"

const WidgetCommittedHerds = () => {
    const [ invoices, setInvoices ] = useState(new WidgetCommittedHerdsDto({}))
    const [ invoicesReady, setInvoicesReady ] = useState(false)
    const [ bulkPayModal, setBulkPayModal ] = useState(false)
    const [ refreshInterval, setRefreshInterval ] = useState(0)
    const [ intervalSeconds, setIntervalSeconds ] = useState(10)

    const { setModalAttr, setModalActive } = useContext(ModalContext)
    const { setOrder, setOnCancelEvent, setOrderOpen } = useContext(CheckoutContext)
    const { account } = useContext(AccountContext)

    useEffect(() => {
        // Check if invoices are available to poll on
        if(invoicesReady && invoices.count > 0) {
            if (refreshInterval > 0) {
                const interval = setInterval(fetchPendingInvoices, refreshInterval);
                return () => clearInterval(interval);
            }
        }
    }, [refreshInterval]);
    // Long poll invoices
    const fetchPendingInvoices = () => InvoicesPending<WidgetCommittedHerdsDto>().then(r => {
        setInvoicesReady(true)
        setInvoices(r)
        // Bump up the polling if there are any in the queue
        if(r.count === 0) {
            setIntervalSeconds(60)
        }
        setRefreshInterval(intervalSeconds * 1000)
    })
    
    const launchBulkPayment = () => {
        InvoicesPayAll<InvoicesPayDto>().then(r => {
            if(r.success)
                window.location.href = r.url;
            else
                throw new Error('An error occurred trying to create a payment.')
        })
    }

    const widgetsAvailable = invoicesReady && invoices.count > 0
    // If not ready, load the invoices that are pending
    useEffect(() => {
        if(!invoicesReady) {
            fetchPendingInvoices();
        }
    }, [invoicesReady]);

    //const countPaid = () => invoices.groups.map((v) => { return v.paid === 1? 0 : 1}).reduce((partialSum: number, a: number) => partialSum + a, 0)

    if(bulkPayModal) {
        setModalAttr({
            text: 'Bulk Pay',
            content: `<p>Do you want to pay for all your groups in one transaction?</p>`,
            buttons:
            <div className="d-flex flex-row gapped mb-3">
                <Button variant="outlined" onClick={() => setBulkPayModal(false) }><ButtonBack text="Cancel" /></Button>
                <Button variant="contained" onClick={() => launchBulkPayment() }>Yes</Button>
            </div>,
            closeEvent: () => setBulkPayModal(false)
        })
        setModalActive(true)
    }

    return widgetsAvailable &&  (
        <div className="span3 span2-md span1-sm mb-3">
            <div id="widget-payment" className={(invoices.count > 0? '' : 'show' ) + 'p-3 shadowed widget highlight main-block'}>
                <div>
                    <h4 className="main-header"><i className="green far fa-check-circle"></i>&nbsp;{__('My Verified Herds', 'myverifiedherds')}</h4>
                    <p>{__('These herds have been CattleProof Verified but have not yet been published to permanent storage for public record.', 'myverifiedherds_p')} {}</p>
                   {/* {((invoices.groups.length > 1) && (countPaid() > 1))? 
                    <div className="pay-all-line">
                        <Tooltip title="Finish Payment and Decentralization"><Button variant="contained" className="mb-3 py-2 txt-white text-white" onClick={() => setBulkPayModal(true)}>Finish All Groups?</Button></Tooltip>
                    </div> : null } */}
                    <ul className="widget-post col-count-2 gapped col-c1-lg">
                        {invoices?.groups && Array.isArray(invoices.groups) && invoices.groups?.map((v) => {
                            return (
                            <li key={v.ID} className={`${v.paid? 'd-flex flex-column' : 'col-count-2 col-c1-md'} gapped`}>
                                <div className={`span${v.paid === 1? '1' : '2' } span1-md`}>
                                    <h6 className={((v.paid === 1 && v.decentralized === 0) || account?.account_type === 'buyer'? 'paid-tag' : '') + ((v.paid !== 1 && account?.account_type !== 'buyer')? ' unpaid-tag ' : '') + ' mb-0'}>{v.group_name}</h6>
                                </div>
                                <div>
                                    <p>There {v.herd_count !== 1? 'are' : 'is'} {v.herd_count} head of cattle registered in this herd.</p>
                                    { v.payment_id? <div className="payment-id">Payment ID: <span>{v.payment_id}</span></div> : null }
                                </div>
                                <div className={v.paid? '' : "align-middle"}>
                                {(v.paid !== 1 && account?.account_type !== 'buyer')? <Button variant="outlined" color="primary" onClick={() => {
                                    setOrderOpen(false)
                                    setOrder({...v, order_type: 'herd', gid: v.ID})
                                    setOnCancelEvent(() => () => setOrder({}))
                                }}>Finish</Button> : <div><CopyAllIcon />&nbsp;Your herd data is being written to the decentralized network. You can wait or continue with another task.</div>}
                                </div>
                            </li>)
                        })}
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default WidgetCommittedHerds;