import { useEffect, useState } from "react"
import { GetCountriesService, GetStatesService } from "../services/locale.service"
import IComponentState, { ComponentStateDto, ComponentStateLoadingDto, ComponentStateReadyDto } from "../interfaces/component-state.interface"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../redux/store"
import { setCountryList } from "../redux/country.slicer"

export const useRegionsHook = () => {
    const [ regions, setRegions ] = useState<IComponentState<any>>(ComponentStateDto)

    useEffect(() => {
        if(!regions.ready) {
            if(!regions.loading) {
                setRegions(ComponentStateLoadingDto)
                GetStatesService('US').then(r => {
                    setRegions({...ComponentStateReadyDto, data: r.data})
                })
            }
        }
    }, [ regions.ready ]);

    return {
        regions, setRegions
    }
}

export const useCountryHook = () => {
    const dispatch = useDispatch();
    const $_country = useSelector((state: RootState) => state.country.state);
    const $_setCountryList = (state: IComponentState<{label: string, value: string}[]>) => dispatch(setCountryList(state));

    const country = $_country;
    const setCountry = $_setCountryList;
    
    useEffect(() => {
        if(!country.ready) {
            if(!country.loading) {
                setCountry(ComponentStateLoadingDto)
                GetCountriesService().then(r => {
                    setCountry({...ComponentStateReadyDto, data: r.data})
                })
            }
        }
    }, [ country.ready ]);

    return {
        country, setCountry
    }
}