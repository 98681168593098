import CowIcon from '/src/media/images/cow-icon-nobkg.svg'
import CowIconFilled from '/src/media/images/cow-icon-nobkg-filled.svg'
import './styles.scss'

export function FasFaFileImport()
{
    return (<i className="fas fa-file-import"></i>)
}

export function FasFaExclamationCircle()
{
    return (<i className="fas fa-exclamation-circle"></i>)
}

export function FasFaHatCowboySide()
{
    return (<i className="fas fa-hat-cowboy-side"></i>)
}
export function FasFaLock()
{
    return (<i className="fas fa-lock"></i>)
}
export function FasFaUnLock()
{
    return (<i className="fas fa-unlock"></i>)
}
export function FasFaPaperPlane()
{
    return (<i className="fas fa-paper-plane"></i>)
}
export function FarFaBell(props?: any)
{
    const iconType = `${(props.toggled !== "undefined" && props.toggled === true)? 'fas' : 'far'}  fa-bell`
    const iconColor = (props.styling !== "undefined")? props.styling : ''
    const iconStyle = `${iconType} ${iconColor}`

    return (<i className={iconStyle}></i>)
}

export function CowHeadIcon(props?: any)
{
    const colors = (props && props.color)? props.color : ''
    let classVal = `${colors} fa-custom cow`
    classVal = `${(props && props.size)? props.size : '' } ${classVal}`
    return  (
        <span className={`${colors} inline`}>{ (props && props.before)? `${props.before} ` : null }<i className={classVal}><img src={CowIcon} alt="cow icon" /></i>{ (props && props.after)? props.after : null }</span>
    )
}

export function CowHeadIconStatic(props?: any)
{
    const colors = (props && props.color)? props.color : ''
    let classVal = `${colors} fa-custom cow`
    classVal = `${(props && props.size)? props.size : '' } ${classVal}`
    return  (
        <div className={`${colors} inline`}>{ (props && props.before)? `${props.before} ` : null }<i className={classVal}><img src={CowIconFilled} alt="cow icon" /></i>{ (props && props.after)? props.after : null }</div>
    )
}

export function FasBlockChain(props?: any)
{
    const classVal = `${(props && props.color)? props.color : '' } fas fa-cube`
    return  (
        <i className={classVal}></i>
    )
}

export function FasFaReceipt(props?: any)
{
    const classVal = `${(props && props.color)? props.color : '' } fas fa-dollar-sign` //fas fa-receipt
    return  (
        <i className={classVal}></i>
    )
}
export function FasFaTruck()
{
    return  (
        <i className="fas fa-truck"></i>
    )
}