import { Button, MenuItem, Select } from '@mui/material'
import { useContext, useEffect, useState } from 'react'
import { ApplicationContext } from '../../providers/application.provider'
import { ModuleTemplatesWizardProviderComponent as ModuleTemplatesWizardComponent } from './templates/wizard'
import { ModuleSubmoduleCalvingGroups } from './submodule/calving-groups'
import { count, empty, exists, scrollToElement } from '../../helpers/util.helper'
import { CalvingGroupCreateService, CalvingGroupUpdateService } from '../../services/calving.groups.service'
import { AlertYinYang } from '../ui/views/alerts'
import { CreateNewGroupButtonComponent } from '../ui/buttons'
import { AccountContext } from '../../providers/account.provider'
import { AdminCertifyOpenModalButton } from '../ui/buttons/button-admin'
import { GroupStatusPending } from '../../enums/group.status.enum'
import { useGroupTypesHook } from '../../hooks/groups.hook'
import { BootStrapSpinner } from '../ui/views/spinner'
import { GroupCreate, GroupUpdate, IGroupTypes } from '../../services/herd-groups'
import { ModuleSubmoduleHerdGroups } from './submodule/herd-groups'
import { useNavigate, useParams } from 'react-router-dom'
import IComponentState, { ComponentStateDto, ComponentStateLoadingDto, ComponentStateReadyDto } from '../../interfaces/component-state.interface'
import ButtonBack from '../ui/buttons/button-back'

interface IModuleComponent
{
  resetGroup?: any
  addHeader?: boolean
  reset?: boolean
}

export const ModuleComponent = ({ resetGroup, addHeader, reset }: IModuleComponent) => {

  const { editable, formState, currentApp, setCurrentApp, setFormId, formId, appLoaded, setReload } = useContext(ApplicationContext)
  const { groupTypes, groupTypesState } = useGroupTypesHook()

  const [ state, setState ] = useState<IComponentState<any>>(ComponentStateDto)
  const [ msg, setMsg ] = useState<any>({msg: '', msgType: false})
  const [ selectedType, setSelectedType ] = useState<string>('')
  const [ certsSelected, setCertsSelected ] = useState<string[]>([])
  const { gid } = useParams()
  const nav = useNavigate()

  let g: IGroupTypes = { name:'', description:'', sort_order: 1, status: 0, created_at:'', certs_available: [], matrix: {
    sav: {
      disable: [],
      enable: []
    },
    usa: {
      disable: [],
      enable: ['sav']
    },
    ibg: {
      disable: ['rab'],
      enable: ['sav']
    },
    rab: {
      disable: ['ibg'],
      enable: ['sav']
    }
  } };

  const getCurrentGroupType = (groupTypeVal: string | number) => {
    if(count(groupTypes) > 0) {
      groupTypes.map(v => {
        if(groupTypeVal === v.ID || groupTypeVal === v.type_id)
          g = v
      })
    }
    return g
  }

  const onSubmitForm = (e: any) => {
    e.preventDefault()
    setMsg({msg: '', type: false})
    const formData: any = new FormData(e.target)
    const f: any = (count(currentApp.answers) > 0)? { gid: currentApp.answers.ID } : {}
    formData.forEach((v: any, k: any) => {
      f[k] = v
    })
    // Add the selected certifications onto the group
    f['selected_certs'] = certsSelected
    setState(ComponentStateLoadingDto)
    let action: any
    if(getCurrentGroupType(selectedType).shortcode === 'herd') {
      action = (formId !== '')? GroupUpdate(f) : GroupCreate(f)
    } else {
      action = (formId !== '')? CalvingGroupUpdateService(f) : CalvingGroupCreateService(f)
    }

    action.then((r: any) => {
      if(r.success) {
        if(formId === '' && r.group?.ID) {
          setFormId(r.group?.ID)
        }
        setCurrentApp({answers: r.group || r.data || {}})
        if(resetGroup)
          resetGroup(ComponentStateDto)
        setMsg({msg: 'Group saved.', type: true})
        setTimeout(() => {
          nav('/')
        }, 2000);
      } else {
        setMsg({msg: r.error, type: false})
      }
      scrollToElement('root')
      setState(ComponentStateReadyDto)
    }).catch((err: any) => console.log(err))
  }

  const selectedGroup: IGroupTypes = groupTypesState?.ready? getCurrentGroupType(currentApp.answers?.group_type_id || selectedType || '') : g;

  const resetCreateEvent = () => {
    setState(ComponentStateDto)
    setSelectedType('')
    setCertsSelected([])
  }

  useEffect(() => {
    if(formState.ready && empty(currentApp) && empty(formId)) {
      setFormId(gid)
      setReload(true)
    }
  }, [ currentApp, formState.ready, formId ])

  useEffect(() => {
    if(formState.ready && currentApp?.answers?.group_type_id && selectedType === '' && groupTypesState.ready) {
      setSelectedType(currentApp.answers.group_type_id);
    }
  }, [ currentApp?.answers?.group_type_id, groupTypesState.ready, formState.ready])
  
  useEffect(() => {
    if(formState.ready && currentApp?.answers?.group_type_id && selectedType === '' && groupTypesState.ready && !reset) {
      setCertsSelected(currentApp?.answers?.selected_certs);
    }
  }, [ currentApp?.answers?.selected_certs, groupTypesState.ready, formState.ready, reset])
  
  useEffect(() => {
    if(reset && count(certsSelected) > 0) {
      setCertsSelected([])
    }
  }, [])
  

  // useEffect(() => {
  //   if(appLoaded && currentApp?.answers?.group_type_id && selectedType === '' && groupTypesState.ready) {
  //     setSelectedType(currentApp.answers.group_type_id);
  //   }
  // }, [ appLoaded, groupTypesState.ready, currentApp?.answers?.group_type_id, formState.ready ])  

  return (
    groupTypesState?.ready && 
    <div>
      { msg.msg !== ''? <AlertYinYang success={msg.type? msg.msg : '' } error={!msg.type? msg.msg : '' } /> : null }
      { formState.ready && appLoaded?
      <>
      <ModuleTemplatesWizardComponent
        state={ state }
        hidden={ false }
        onSubmitForm={ onSubmitForm }
        nextButton={
          <>
            { selectedType !== '' && !currentApp.answers?.ID? 
            <Button variant='outlined' onClick={() => nav('/')}><ButtonBack text="Cancel" /></Button> : null }
            <GroupEditActionButton
              selectedGroup={ selectedGroup }
            />
          </>
        }>

        <GroupEditHeader
          addHeader={ addHeader }
          resetCreateEvent={ resetCreateEvent }
          resetGroup={ resetGroup }
        />

        {
          groupTypesState.ready?
          <div className="section col-count- gapped">
            <GroupTypeInstructionsComponent
              selectedType={ selectedType }
            />
            
            <CertsListComponent
              certsSelected={ certsSelected }
              selectedGroup={ selectedGroup }
              selectedType={ selectedType }
              getCurrentGroupType={ getCurrentGroupType }
              setCertsSelected={ setCertsSelected }
            />
            
            <GroupTypeSelectionComponent
              selectedType={ selectedType }
              editable={ editable || false }
              resetCreateEvent={ resetCreateEvent }
              setSelectedType={ setSelectedType }
              groupTypes={ groupTypes }
            />

            { selectedType === ''? 
            <div className="align-middle mt-4">
              <Button variant='outlined' onClick={() => nav('/')}><ButtonBack text="Cancel" /></Button>
            </div> : null }

            <SelectedGroupSubmoduleComponent
              certsSelected={ certsSelected }
              selectedGroup={ selectedGroup }
            />

            { msg.msg !== ''? <AlertYinYang success={msg.type? msg.msg : '' } error={!msg.type? msg.msg : '' } /> : null }

        </div>
        :
        <BootStrapSpinner /> }
      
      </ModuleTemplatesWizardComponent>
      </>
      : <div className="align-middle p-4 mt-4">
          <BootStrapSpinner />
        </div> }
    </div>
  )
}

const init = (shortcode: string) => {
  switch (shortcode) {
    case ('calving'):
      return ModuleSubmoduleCalvingGroups
    case ('herd'):
      return ModuleSubmoduleHerdGroups
    default:
      return null;
  }
}

interface ISelectedGroupSubmoduleComponent
{
  selectedGroup: any
  certsSelected: any
}

export const SelectedGroupSubmoduleComponent = ({ certsSelected, selectedGroup }: ISelectedGroupSubmoduleComponent) => {

  const subModule = init(selectedGroup?.shortcode || '')

  if(subModule === null)
    return null

  return (
    <ModuleSubmoduleCalvingGroups
      certsAvailable={selectedGroup.certs_available || []}
      certsSelected={certsSelected}
      groupType={ selectedGroup.shortcode }
    />
  )
}

interface IGroupTypeInstructionsComponent
{
  selectedType: string
}

export const GroupTypeInstructionsComponent = ({ selectedType }: IGroupTypeInstructionsComponent) => {
  const { currentApp } = useContext(ApplicationContext)
  
  return (
    selectedType === '' && !currentApp.answers?.group_type?
    <>
    <h4 className="main-header mb-0 pb-0">Select Group Type</h4>
    <p>Each group type will have different options for certification associated with them. Choose one for more information and to get started.</p>
    </> :
    <p>Select which certifications you wish to have CattleProof Verified. Adding certifications may add more support documents to validate certifications.</p>
  )
}

interface ICertsListComponent
{
  selectedGroup: IGroupTypes
  certsSelected: string[]
  selectedType: string
  getCurrentGroupType: (groupTypeVal: string | number) => IGroupTypes
  setCertsSelected: (certs: string[]) => void
}

export const CertsListComponent = ({
  selectedGroup,
  certsSelected,
  getCurrentGroupType,
  setCertsSelected,
  selectedType
}: ICertsListComponent) => {

  const setButtonMatrix = (key: string) => {
    if(empty(selectedType))
      return [];
    const matrix = getCurrentGroupType(selectedType).matrix;
    const selectedItem = matrix[key];
    let enabled = [...certsSelected]
    if (enabled.includes(key)) {
      // Remove the item if it's already enabled
      enabled = enabled.filter(item => item !== key);
      // Remove any items that depend on the removed key
      enabled = enabled.filter(item => !matrix[item]?.enable.includes(key));
  
      // Recheck other enabled items' requirements to ensure required elements stay
      for (const item of enabled) {
        const itemConfig = matrix[item];
        if(itemConfig?.enable) {
          for (const req of itemConfig.enable) {
            if (!enabled.includes(req)) {
              enabled.push(req); // Add required elements back if they were removed
            }
          }
        }
      }
    } else {
      // Add new key and enforce enabling required elements
      if(selectedItem?.enable) {
        for (const req of selectedItem?.enable) {
          if (!enabled.includes(req)) {
            enabled.push(req);
          }
        }
      }
      // Add the new key itself
      if (!enabled.includes(key)) {
        enabled.push(key);
      }  
      // Disable any elements listed in "disable"
      for (const disableKey of selectedItem?.disable) {
        enabled = enabled.filter(item => item !== disableKey);
      }
    }
    setCertsSelected(enabled);
  };

  return (
    selectedGroup && !empty(selectedGroup.certs_available) && (
    <div className={`col-count-2 col-c1-md gapped mb-4 pb-4`}>
        { selectedGroup.certs_available.map((v: any, k: any) => (
            <div key={`cert-list-available-${k}`} className={`cert-list-item gapped ${exists(certsSelected, v.shortcode)? 'active' : ''}`}>
              <div style={{borderRadius: '50%'}}><img src={v.image} style={{maxWidth: '60px'}} /></div>
              <div>
                <h6><strong>{ v.name }</strong></h6>
                <p className='legal'>{ v.description }</p>
              </div>
              <div className="align-middle span2">
                  <Button variant={ exists(certsSelected, v.shortcode)? 'contained' : 'outlined'} size='small' onClick={() => {
                    setButtonMatrix(v.shortcode)
                    //addCertToOrder(v.shortcode)
                  }}>{ !exists(certsSelected, v.shortcode)? `Add` : `Remove`} Certification</Button>
                </div>
            </div>
          )) }
    </div>)
  )
}

interface IGroupTypeSelectionComponent
{
  selectedType: string
  editable: boolean
  resetCreateEvent: any
  setSelectedType: any
  groupTypes: any[]
}

export const GroupTypeSelectionComponent = ({
  selectedType,
  editable,
  resetCreateEvent,
  setSelectedType,
  groupTypes,
}: IGroupTypeSelectionComponent) => {
  const { currentApp } = useContext(ApplicationContext)

  return (
  !currentApp.answers?.group_type? 
    <div className={selectedType !== ''? 'youve-selected' : 'col-count-'}>
      { selectedType !== ''?
      <div className='align-middle'>
        <strong>Selected:</strong>
      </div> : null }
      <Select
        required
        value={ selectedType }
        disabled={ !editable }
        onChange={(e: any) => {
          resetCreateEvent()
          setSelectedType(e.target.value)
        }}
      >
        {
          groupTypes.map((v, k) => {
            return (
              <MenuItem key={`group-types-${k+1}`} value={ v.ID }>{ v.name }</MenuItem>
            )
          })
        }
      </Select>
    </div>
  : null
  )
}

interface IGroupEditBackButton
{
  selectedGroup: any
}

export const GroupEditActionButton = ({ selectedGroup }: IGroupEditBackButton) => {
  const nav = useNavigate()
  const { currentApp } = useContext(ApplicationContext)
  return (
    selectedGroup.ID? 
      <div className={count(currentApp.answers) > 0? 'col-count-2 gapped' : ''}>
        {count(currentApp.answers) > 0? <Button variant='outlined' className='corp' onClick={() => nav('/') }><ButtonBack /></Button> : null }
        <Button variant='contained' className='corp' type='submit'>{ count(currentApp.answers) > 0? 'Update' : 'Create'}</Button>
      </div> : <></>
  )
}

interface IGroupEditHeader
{
  addHeader: any
  resetCreateEvent: any
  resetGroup: any
}

export const GroupEditHeader = ({
  addHeader,
  resetCreateEvent,
  resetGroup
}: IGroupEditHeader) => {
  const { currentApp, formId, setToggleForm } = useContext(ApplicationContext)
  const { account } = useContext(AccountContext)
  const nav = useNavigate()
  return (
    <div className='d-flex justify-content-between mb-4 pb-2'>
      { addHeader? <h4 className='main-header'>
        <Button onClick={() => {
            setToggleForm(false)
            resetCreateEvent()
            nav('/')
          }} className='no-appearance'>
          <i className="fas fa-chevron-left"></i>
        </Button>&nbsp;<strong>{formId !== ''? 'Edit' : 'Create'} Group</strong>
      </h4> : null }
      { formId !== '' && !account.isAdmin && addHeader? <CreateNewGroupButtonComponent resetGroup={(someState: IComponentState<any>) => {
          if(resetGroup)
            resetGroup(someState)
          resetCreateEvent()
        }} /> : null }
      { formId !== '' && account.isAdmin? <GroupStatusPending status={ currentApp.answers.status }><AdminCertifyOpenModalButton group={ currentApp.answers } /></GroupStatusPending> : null }
    </div>
  )
}