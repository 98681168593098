import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import IComponentState, { ComponentStateDto } from "../interfaces/component-state.interface";
import { IDispositionForm } from "../pages/disposition";
import { IGroupsHook } from "../hooks/groups.hook";

export interface IDispositionSlicer 
{
    list: IDispositionForm[];
    form: IDispositionForm;
    state: IComponentState<any>;
    formState: IComponentState<any>;
    availableHerds: IComponentState<IGroupsHook>;
}

const initialState: IDispositionSlicer = {
    list: [],
    state: ComponentStateDto,
    form: {},
    formState: ComponentStateDto,
    availableHerds: ComponentStateDto
};

const reducer = createSlice({
    name: 'disposition',
    initialState,
    reducers: {
        setDispositionGroup: (state, action: PayloadAction<IDispositionForm>) => {
            state.form = action.payload;
        },
        setDispositionList: (state, action: PayloadAction<IDispositionForm[]>) => {
            state.list = action.payload;
        },
        setDispositionState: (state, action: PayloadAction<IComponentState<any>>) => {
            state.state = action.payload;
        },
        setFormState: (state, action: PayloadAction<IComponentState<any>>) => {
            state.formState = action.payload;
        },
        setAvailableHerds: (state, action: PayloadAction<IComponentState<IGroupsHook>>) => {
            state.availableHerds = action.payload;
        }
    }
});

export const { setDispositionGroup, setDispositionList, setDispositionState, setFormState, setAvailableHerds } = reducer.actions;
export default reducer.reducer;