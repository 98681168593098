import React, { createContext, useState, ReactNode, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { setDispositionGroup, setDispositionList, setDispositionState, setFormState, setAvailableHerds } from '../../redux/disposition.slicer';
import { IResponse } from '../../interfaces/response.interface';
import { IGroupsHook } from '../../hooks/groups.hook';
import { DispositionGetAvailableHerdGroupsService, DispositionGetService } from '../../services/disposition.service';
import { useParams } from 'react-router-dom';
import { IDispositionForm } from '.';
import IComponentState, { ComponentStateDto, ComponentStateLoadingDto, ComponentStateReadyDto } from '../../interfaces/component-state.interface';

interface IDispositionContext {
    disposition: any;
    setDisposition: (group: any) => void;

    dispositionState: IComponentState<any>;
    setDispositionState: (state: IComponentState<any>) => void;

    dispositionList: any[];
    setDispositionList: (list: IDispositionForm[]) => void;
    
    dispositionListState: IComponentState<any>;
    setDispositionListState: (state: IComponentState<any>) => void;

    availableHerds: IComponentState<IGroupsHook>;
    setAvailableHerds: (state: IComponentState<any>) => void;
    refreshDisposition: () => void;
}

const init: IDispositionContext = {
    disposition: {},
    setDisposition: () => {},
    dispositionState: ComponentStateDto,
    setDispositionState: () => {},
    dispositionList: [],
    setDispositionList: () => {},
    dispositionListState: ComponentStateDto,
    setDispositionListState: () => {},
    availableHerds: {...ComponentStateDto, data: {count: 0, groups: [], uid: '', valid: false}},
    setAvailableHerds: () => {},
    refreshDisposition: () => {},
};

export const DispositionContext = createContext<IDispositionContext>(init);

const DispositionProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [ refresh, setReresh ] = useState<boolean>(false);
    const { gid } = useParams();

    const dispatch = useDispatch();
    const list = useSelector((state: RootState) => state.disposition.list);
    const form = useSelector((state: RootState) => state.disposition.form);
    const state = useSelector((state: RootState) => state.disposition.state);
    const formState = useSelector((state: RootState) => state.disposition.formState);
    const availableHerds = useSelector((state: RootState) => state.disposition.availableHerds);

    const $_setDispositionGroup = (group: any) => dispatch(setDispositionGroup(group));
    const $_setDispositionFormState = (formState: IComponentState<any>) => dispatch(setFormState(formState));
    const $_setDispositionList = (list: IDispositionForm[]) => dispatch(setDispositionList(list));
    const $_setDispositionState = (state: IComponentState<any>) => dispatch(setDispositionState(state));
    const $_setAvailableHerds = (state: IComponentState<IGroupsHook>) => dispatch(setAvailableHerds(state));

    const onNewPage: boolean = gid === 'create';
    const onExistingPage: boolean = !onNewPage && typeof gid !== "undefined";
    const onSinglePage: boolean = onNewPage || onExistingPage;

    const fetchHerdData = (func?: () => void) => {
        DispositionGetAvailableHerdGroupsService().then((r: IResponse<IGroupsHook>) => {
            $_setAvailableHerds({...ComponentStateReadyDto, data: r.data});
            if(func) {
                func();
            }
        });
    }

    const refreshDisposition = () => {
        $_setDispositionFormState(ComponentStateLoadingDto);
        fetchHerdData(() => {
            DispositionGetService(gid).then(r => {
                $_setDispositionGroup(r.data);
                $_setDispositionFormState(ComponentStateReadyDto);
            });
        });
    }

    /**
     * This will load/set the disposition group list
     */
    useEffect(() => {
        if(!onSinglePage) {
            if(!state.ready) {
                if(!state.loading) {
                    $_setDispositionState(ComponentStateLoadingDto);
                    fetchHerdData(() => {
                        DispositionGetService().then((r: IResponse<any>) => {
                            $_setDispositionList(r.data);
                            $_setDispositionState(ComponentStateReadyDto);
                        });
                    });
                }
            }
        }
    }, [ state.ready, onSinglePage ]);
    /**
     * This will load/set the disposition form and available herds
     */
    useEffect(() => {
        if(onSinglePage) { 
            if(onExistingPage) {
                if(!formState.loading) {
                    refreshDisposition();
                }
            } else {
                fetchHerdData(() => {
                    $_setDispositionFormState(ComponentStateReadyDto);
                });
            }
        }
    }, [ onSinglePage, gid ]);

    useEffect(() => {
      if(refresh) {
        setReresh(false);
        refreshDisposition();
      }
    }, [ refresh ]);    
    

    return (
        <DispositionContext.Provider
            value={{
                disposition: form,
                dispositionState: formState,
                dispositionList: list,
                dispositionListState: state,
                availableHerds,
                setDisposition: $_setDispositionGroup,
                setDispositionState: $_setDispositionFormState,
                setDispositionList: $_setDispositionList,
                setDispositionListState: $_setDispositionState,
                setAvailableHerds: $_setAvailableHerds,
                refreshDisposition: () => setReresh(true),
            }}
        >
            { availableHerds.ready && !onSinglePage && state.ready && children }
            { availableHerds.ready && onSinglePage && !formState.loading && children }
        </DispositionContext.Provider>
    );
};

export default DispositionProvider;