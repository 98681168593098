import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import IComponentState, { ComponentStateDto } from "../interfaces/component-state.interface";

export interface IDispositionSlicer
{
    state: IComponentState<{label:string, value: string}[]>;
}

const initialState = {
    state: ComponentStateDto,
};

const reducer = createSlice({
    name: 'country',
    initialState: initialState,
    reducers: {
        setCountryList: (state, action: PayloadAction<IComponentState<{label: string, value: string}[]>>) => {
            state.state = action.payload;
        },
    }
});

export const { setCountryList } = reducer.actions;
export default reducer.reducer;