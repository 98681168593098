import { Button } from "@mui/material";
import { CowHeadIcon } from "../fontawesome";
import { ReactNode, useContext, useState } from "react";
import { ApplicationContext } from "../../../providers/application.provider";
import { useNavigate } from "react-router-dom";
import { IContextChildren } from "../../../interfaces/context-children.inteface";
import EditIcon from '@mui/icons-material/Edit';
import ButtonEdit from "./button-edit"
import AddIcon from '@mui/icons-material/Add';
import __ from "../../../services/translator"

interface ICreateNewGroupButtonComponent
{
    onClickEvent?: any
    resetGroup: any
    disabled?: boolean
    title?: string | JSX.Element
    variant?: 'cow' | 'edit' | 'none' | 'add'
}

export const CreateNewGroupButtonComponent = ({ onClickEvent, disabled, title, variant = 'cow' }: ICreateNewGroupButtonComponent) => {
    const nav = useNavigate()
    const { setClearForm } = useContext(ApplicationContext)

    const defaultEvent = () => {
        //setToggleForm(true)
        setClearForm(true)
        // setFormContent(<ModuleComponent resetGroup={ resetGroup } />)
        nav('/create/group/')
    }

    return (
        <Button
            disabled={ disabled }
            variant="outlined"
            onClick={ () => {
                if(onClickEvent) {
                    onClickEvent(() => defaultEvent())
                } else {
                    defaultEvent()
                }
            } }
        >
            { variant === 'cow' && <CowHeadIcon color="blue" />}
            { variant === 'none' && ''}
            { variant === 'add' && <AddIcon />}
            { variant === 'edit' && <EditIcon />}
            &nbsp;{ title || 'Create Group'}
        </Button>
    )
}

export interface IButtonAny
{
    text?: string | JSX.Element | ReactNode;
    className?: string;
}

export const ButtonForward = ({ text, className }: IButtonAny) => {
    return (
        <div className={ `strong ${className || ''}` }>
            <i className="fas fa-angle-double-right"></i>&nbsp;{ text || __('Next', 'next') }
        </div>
    )
}

interface IButtonsTableMiniActions extends IContextChildren
{
    onDeleteEvent?: () => void
    onEditEvent?: () => void
    onDownloadEvent?: () => void,
    disableDelete?: boolean
}

export const ButtonsTableMiniActions = ({ children, onDeleteEvent, onEditEvent, onDownloadEvent, disableDelete } : IButtonsTableMiniActions) => {
    const [ toggle, setToggle ] = useState<boolean>(false);
    return (
        toggle? 
        <>
        <button className="no-appearance" onClick={ onDeleteEvent }><i className="far fa-trash-alt red"></i></button>
        <button className="no-appearance" onClick={() => setToggle(!toggle) }><i className="fas fa-ban"></i></button>
        </> : 
        <>
        { onDownloadEvent && <button className="no-appearance" onClick={ onDownloadEvent }><i className="fas fa-download"></i></button> }
        { onEditEvent && <button className="no-appearance" onClick={ onEditEvent }><ButtonEdit text={<></>} /></button>}
        { !disableDelete && <button className="no-appearance" onClick={() => setToggle(!toggle)}><i className="far fa-trash-alt"></i></button> }
        { children || null }
        </>
    )
}