import HttpClient from "../helpers/client";
import { IResponse } from "../interfaces/response.interface";

export const DispositionGetAvailableHerdGroupsService = () => (new HttpClient()).get<IResponse<any>>(`disposition/groups`)

export const DispositionGetService = (id?: string) => (new HttpClient()).get<IResponse<any>>(`disposition${id? `/${id}/` : ''}`)

export const DispositionCreateService = (body: any) => (new HttpClient()).post<IResponse<any>>(`disposition`, body)

export const DispositionUpdateService = (body: any) => (new HttpClient()).patch<IResponse<any>>(`disposition`, body)

export const DispositionDeleteService = (id: string) => (new HttpClient()).delete<IResponse<any>>(`disposition/${id}`)

export const DispositionSaveAnimalsService = () => (new HttpClient()).post<IResponse<any>>(`disposition/animals/`)

export const DispositionGetAnimalsService = (ID?: string) => (new HttpClient()).get<IResponse<any>>(`disposition/animals/${ID? ID : ''}`)

export const DispositionGetAnimalsSearchService = (ID: string, search?: string) => (new HttpClient()).get<IResponse<any>>(`disposition/animals/${ID}`, search? {search} : {})

export const DispositionUpdateAnimalsService = () => (new HttpClient()).patch<IResponse<any>>(`disposition/animals/`)

export const DispositionDeleteAnimalMultiService = (did: string, body: string[] | { hid: string }) => (new HttpClient()).delete<IResponse<any>>(`disposition/animals/${did}`, body)

export const DispositionDeleteAnimalsService = (did: string, hid: string) => DispositionDeleteAnimalMultiService(did, { hid });
