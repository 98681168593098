import { ReactNode, useContext } from "react";
import { IContextChildren } from "../../interfaces/context-children.inteface";
import { AccountContext } from "../../providers/account.provider";
import AlertBox from "../upload/alert";

interface IPermissionComponent extends IContextChildren
{
    allowedAccount: string[]
    showAlert?: boolean
    message?: string | ReactNode | JSX.Element
}

const PermissionComponent = ({ children, allowedAccount, message, showAlert = false } : IPermissionComponent): JSX.Element | ReactNode => {
    const { account } = useContext(AccountContext);
    return (
        allowedAccount.includes(account.account_type)? children : showAlert? <AlertBox errorMessage={ message ||  "You are not allowed to view this content or it has moved."} errorClass="danger" /> : null
    )
};

export default PermissionComponent;