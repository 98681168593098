import { DataGrid, GridColDef, GridRowModesModel } from "@mui/x-data-grid";
import { useContext, useState } from "react";
import { AlertContext } from "../../../providers/alert.provider";
import { DispositionDeleteService } from "../../../services/disposition.service";
import { count } from "../../../helpers/util.helper";
import { DispositionStatusEnum, DispositionStatusEnumBadge, DispositionTypeEnum, DispositionTypeEnumBadge } from "../../../enums/disposition.enum";
import { UserToolGridMenuComponent } from "../../../components/user-tools/grid-menu.tsx";
import { Tooltip } from "@mui/material";
import { SSOHook } from "../../../hooks/sso.hook.tsx";
import { BootStrapSpinner } from "../../../components/ui/views/spinner/index.tsx";
import IComponentState, { ComponentStateDto, ComponentStateLoadingDto, ComponentStateReadyDto } from "../../../interfaces/component-state.interface";
import PermissionsDataGridRowsComponent from "../../../components/permissions/datagrid-rows.tsx";
import StorefrontIcon from '@mui/icons-material/Storefront';
import DownloadIcon from '@mui/icons-material/Download';
import { environment } from "../../../environmentals/config.tsx";
import useToken from "../../../helpers/token.tsx";

export const DispositionDashboard = ({ groups, reloadEvent, createButton, editGroupEvent }: any) => {
    const [ rowModesModel, setRowModesModel ] = useState<GridRowModesModel>({});
    const [ state, setState ] = useState<IComponentState<any>>(ComponentStateDto);
    const { setMsg, setType } = useContext(AlertContext);
    const { setTriggerSso, setUri } = SSOHook();
    const { token } = useToken();

    const onDeleteEvent = (id: string) => {
        setState(ComponentStateLoadingDto)
        DispositionDeleteService(id).then((r: any) => {
            if(r.success) {
                setMsg('Successfully deleted')
                setType(true)
            } else {
                setMsg(r.error)
                setType(false)
            }
            reloadEvent()
            setState(ComponentStateReadyDto)
        })
    }

    const dataTableColumns: GridColDef[] = [
        // {field: 'id', headerName: 'ID' },
        // {field: 'gid', headerName: 'Group Id' },
        { field: 'title', headerName: 'Name', flex: 1, minWidth: 150, editable: false },
        {
            field: 'description',
            headerName: 'Description',
            flex: 2,
            minWidth: 250,
            editable: false,
            renderCell: e => (count(e.row.description) > 70)? `${e.row.description.substring(0, 70)}...` : e.row.description
        },
        {
            field: 'herd',
            cellClassName: 'justify-content-center',
            headerName: 'Animals',
            minWidth: 50,
            editable: false,
            renderCell: (e: any) => {
                return (
                    <div>
                        {e.row.herd}
                    </div>
                )
            }
        },
        {
            field: 'status',
            cellClassName: 'justify-content-center',
            headerName: 'Status',
            minWidth: 50,
            editable: false,
            renderCell: e => <DispositionStatusEnumBadge status={ e.row.status } />
        },
        { field: 'assigned', headerName: 'Assigned', flex: 1, minWidth: 150, renderCell: (e) => e.row?.assigned?.length > 0? <StorefrontIcon style={{color: 'green'}} /> : '' },
        {
            field: 'action',
            headerName: '',
            flex: 1,
            minWidth: 100,
            editable: false,
            cellClassName: 'justify-content-end align-content-center',
            renderCell: e => {
                const readOnly: boolean = [DispositionStatusEnum.archived, DispositionStatusEnum.locked].includes(e.row.status) || e.row?.assigned?.length > 0
                return (
                    !state.loading? 
                    <>
                    { e.row.herd > 0 && (
                        <div className="mx-2">
                            <a
                                href={`${environment.api}/generate/pdf/disposition?ID=${e.row.ID}&jwtToken=${token}`}
                                className="no-appearance txt-black"
                            >
                                <DownloadIcon />
                            </a>
                        </div>
                    )}
                    <UserToolGridMenuComponent
                        readOnly={ readOnly }
                        deleteEvent={ () => onDeleteEvent(e.row.ID) }
                        editEvent={ () => editGroupEvent(e.row) }
                        data={ e.row }
                        buttonDeck={ <>
                            { e.row.herd > 0 && e.row.status === 2 && (
                            <div className="align-middle px-2">
                                <Tooltip title={ (e.row.type === DispositionTypeEnum.sale)? "Create/Edit Marketplace Listing" : ''}>
                                    <div>
                                        <DispositionTypeEnumBadge
                                            status={ e.row.type }
                                            size="medium"
                                            className={ e.row.type === DispositionTypeEnum.sale? 'pointer hover-opacity' : '' }
                                            onClick={() => {
                                                if(e.row.type === DispositionTypeEnum.sale) {
                                                    setUri('edit/marketplace/listings')
                                                    setTriggerSso(true)
                                                }
                                            } }
                                        />
                                    </div>
                                </Tooltip>
                            </div> )}
                        </> }
                    />
                    </>
                    : <BootStrapSpinner size="x-sm" />
                )
            }
        },
        ]
    return (
        <PermissionsDataGridRowsComponent
            label={ createButton }
            rows={ groups }
        >
            <DataGrid
                getRowId={e => e.ID }
                //onProcessRowUpdateError={(e) => e}
                rowModesModel={ rowModesModel }
                onRowModesModelChange={ setRowModesModel }
                // onCellEditStart={() => {
                // }}
                // processRowUpdate={(e: GridRowModel) => {
                //     console.log(e)
                //     // updateAttribute({
                //     //     field: dataTableTarget,
                //     //     gid: gid,
                //     //     rowId: e.ID,
                //     //     value: e[dataTableTarget as keyof typeof e]
                //     // })
                //     return e
                // }}
                rows={ groups }
                columns={ dataTableColumns }
                checkboxSelection={ false }
            />
        </PermissionsDataGridRowsComponent>
    )
}