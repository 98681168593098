import { useEffect, useState } from "react"
import { DataGrid, GridColDef, GridRowModel } from "@mui/x-data-grid"
import { Button, TextField } from "@mui/material"
import { BootStrapSpinner } from "../../ui/views/spinner"
import { IResponse } from "../../../interfaces/response.interface"
import { useNavigate } from "react-router-dom"
import ButtonBack from "../../ui/buttons/button-back"

interface IAnimalTableEditorComponent<T>
{
    ready: boolean
    columns: GridColDef[]
    rows: T[]
    setRows: any
    onLoadEvent?: any
    setDeleteEvent?: any
    submitEvent: any
    submitSearch: any
    onUpdateEvent?: any
    backButtonUri?: string
    backButtonEvent?: CallableFunction,
    onSubmitMultiDeleteEvent?: (body: string[], func?: (r: IResponse<any>) => void) => void,
    locked?: boolean
}

const AnimalTableEditorComponent = ({
    columns,
    rows,
    onLoadEvent,
    submitEvent,
    submitSearch,
    onUpdateEvent,
    backButtonUri,
    backButtonEvent,
    onSubmitMultiDeleteEvent,
    ready,
    locked
}: IAnimalTableEditorComponent<any>) => {
    const [ dataColumns ] = useState<GridColDef[]>(columns)
    const [ dataRows, setDataRows ] = useState(rows)
    const [ search, setSearch ] = useState<string>('')
    const [ multiSelect, setMulitSelect ] = useState<string[]>([]);
    const nav = useNavigate()
    //const { setMsg, setType } = useContext(AlertContext)

    useEffect(() => {
        if(onLoadEvent) {
            onLoadEvent().then((r: IResponse<any>) => setDataRows(r.data))
        }
    }, [])

    return (
        ready?
        <div>
            { backButtonUri || backButtonEvent? <Button variant="outlined" className="mb-2" onClick={ () => backButtonEvent? backButtonEvent() : nav(backButtonUri || '') }><ButtonBack /></Button> : null }
            <form onSubmit={(e) => {
                e.preventDefault()
                submitEvent(search)
            }}>
                <div className="d-flex gapped p-3 bkg-light-grey rounded border border-secondary mt-3 search-box-container">
                    <TextField value={ search } label="Search Herd Group" onChange={e => setSearch(e.target.value)} />
                    <Button variant="contained"  onClick={() => submitSearch(search).then((r: IResponse<any>) => {
                        setDataRows(r.data)
                    })}><i className="fas fa-search"></i>Search</Button>
                    <Button variant="contained" disabled={search === ''} onClick={() => {
                        onLoadEvent().then((r: IResponse<any>) => {
                            setSearch('')
                            setDataRows(r.data)
                        })
                    }}><i className="fas fa-eraser"></i>Clear</Button>
                    
                </div>
            </form>

            <div className="mt-3" style={ { height: 'auto' } }>
                { onSubmitMultiDeleteEvent && !locked? 
                <div className="my-4">
                    <Button variant="contained" disabled={ multiSelect.length === 0 } onClick={() => {
                        onSubmitMultiDeleteEvent(multiSelect, (r: IResponse<any>) => {
                            if(r.success) {
                                setDataRows(dataRows.filter((e: any) => multiSelect.indexOf(e.ID) === -1))
                            }
                        })
                    }}>Delete</Button>
                </div> : null }
                <DataGrid
                    getRowId={e => e.ID }
                    onRowSelectionModelChange={ (v: any) => setMulitSelect(v) }
                    processRowUpdate={(e: GridRowModel) => {
                        if(onUpdateEvent)
                            onUpdateEvent(e)
                        return e
                    }}
                    rows={ dataRows }
                    columns={ dataColumns }
                    checkboxSelection={ !locked }
                    // onProcessRowUpdateError={(e) => {
                    //     console.log(e)
                    // }}
                    //rowModesModel={ rowModesModel }
                    // onRowModesModelChange={ (e) =>{
                    //     console.log(e)
                    //     setRowModesModel(e)
                    // }}
                    // onCellEditStart={(e) => {
                    //     setDataTableTarget(e.field)
                    // }}
                />
            </div>
        </div> : <BootStrapSpinner />
    )
}

export default AnimalTableEditorComponent