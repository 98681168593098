import { useNavigate, useParams } from "react-router-dom"
import { CreateNewGroupButtonComponent } from "../../components/ui/buttons"
import { BootStrapSpinner } from "../../components/ui/views/spinner"
import { empty, getQueryParam } from "../../helpers/util.helper"
import { useContext, useEffect } from "react"
import { DispositionComponent } from "./components"
import { ComponentStateDto } from "../../interfaces/component-state.interface"
import { DispositionContext } from "./provider"
import PlaceIcon from '@mui/icons-material/Place'
import Template from "../../templates/cattleproof"
import { AlertContext } from "../../providers/alert.provider"

export interface IDispositionForm
{
    ID?: string
    unique_id?: string
    uid?: string
    did?: string
    title?: string
    description?: string
    price?: string | number
    weight?: number | string
    status?: number
    herd?: number
    price_includes_transport?: number | boolean
    created_at  ?: string | null
    completed_at?: string | null
    deleted_at?: string | null
    updated_at?: string | null
    destination_id?: string | null
}

export const DispositionPage = () => {
    const { setMsg, setType, setAutoClose, setCloseable } = useContext(AlertContext);
    const {
        dispositionState,
        disposition,
        setDisposition,
        dispositionListState,
        setDispositionListState,
        availableHerds,
        refreshDisposition
    } = useContext(DispositionContext);
    const { gid } = useParams();
    const nav = useNavigate();

    const groups = availableHerds?.data || { count: 0, groups: [], uid: '', valid: false };
    const groupState = gid? dispositionState : dispositionListState;
    const formData = disposition;
    
    const onEditEvent = (data: any) => {
        nav(`/disposition/${empty(data)? '' : data.ID}/`);
        setDispositionListState(ComponentStateDto);
    }

    const onClickEventGoButton = () => {
        nav('/disposition/create/');
        setDisposition({});
        refreshDisposition();
    }
    
    const onClickEventGoBackButton = () => {
        nav(`/disposition/`);
        setDispositionListState(ComponentStateDto);
    }
    
    const defTitle: JSX.Element = <><PlaceIcon />&nbsp;My Disposition Groups</>

    const isFocused: boolean = !empty(gid);
    
    const isUpdateFocused: boolean = isFocused && gid !== 'create';
    
    const isNewFocused: boolean = isFocused && gid === 'create';

    useEffect(() => {
        const params = getQueryParam('error');
        const errorCode: number = parseInt(params.value);
        if(errorCode && errorCode === 403) {
            params.clear();
            setAutoClose(5000);
            setCloseable(true);
            setMsg('Disposition document is not available. Access is likely restricted.');
            setType(false);
        }
    }, []);
    

    return (
        <Template
            title={ isNewFocused? formData?.title || `Create` : isUpdateFocused? (formData?.title || defTitle) : defTitle }
            description={ `Manage your disposition groups. These groups will be available in the Marketplace or available to transport when required.` }
            backButtonLabel={ ( isNewFocused || isUpdateFocused )? defTitle : `` }
            backButtonEvent={ !empty(gid) ? onClickEventGoBackButton : undefined }
            createButton={ (
                <CreateNewGroupButtonComponent
                    resetGroup={() => {}}
                    title="Disposition Group"
                    onClickEvent={ onClickEventGoButton }
                    variant="add"
                />
                // <CreateNewGroupButtonComponent
                //     resetGroup={() => {}}
                //     title={`Create ${empty(groups.groups)? 'Animal' : 'Disposition'} Group`}
                //     onClickEvent={ onClickEventGoButton }
                // />
                ) }
        >
            { groupState.ready? 
                empty(!groups.groups)? 
                <DispositionComponent
                    groups={ groups }
                    backButtonEvent={ onClickEventGoBackButton }
                    createEvent={ onClickEventGoButton }
                    setUpdateEvent={ onEditEvent }
                /> : <div className="align-middle"><p>No groups available. Please create a group.</p></div>
                : 
                <BootStrapSpinner />
            }
        </Template>
    )
}