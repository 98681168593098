import HttpClient from "../helpers/client"
import { IAnyType } from "../interfaces/form-builder.interface"
import { IResponse } from "../interfaces/response.interface"

export interface IAnimalSearchGet
{
    ID: string,
    eid: number | string,
    blockchain_cid: string,
    currently_viewing: boolean,
    cid: string | null,
    found: boolean,
    url: string,
    decentralized_at: string,
    ipfs: IAnyType
}

export const AnimalSearchGet = async (id: string | number, type: string): Promise<IResponse<IAnimalSearchGet[]>> => await (new HttpClient()).get<IResponse<IAnimalSearchGet[]>>(`animal-search/${type}/${id}`);