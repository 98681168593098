import React from 'react';
import { IAnyType } from '../../interfaces/form-builder.interface';
//import { Button } from '@mui/material';
//import ButtonDelete from '../../components/ui/buttons/button-delete';
import StandardTable from './standard-table';
import __ from '../../services/translator';
import { Typography } from '@mui/material';

const ExplorerPageSingleCidComponent: React.FC<{
    url?: string,
    cid?: string,
    clearSearch?: () => void,
    id?: string,
    repValues: any,
    repKeys: any,
    openExternalLink: (url?: string) => void,
    animalData: IAnyType
}> = ({ animalData, openExternalLink, repKeys, repValues, cid, url}) => {
    return (
        <div className="all-data">
            <h4><strong>Animal Information</strong></h4>
            <h5>Program Compliant Tag (PCT) ID: { animalData.eid || animalData['PCT ID'] || '' }</h5>
            <Typography variant='body1'>Blockchain ID (CID): <a href="#" onClick={() => openExternalLink(url)}>{ cid }</a></Typography>
            <Typography variant='body1'>IPFS Link: <a href="#" onClick={() => openExternalLink(url)}>{ url }</a></Typography>
            { animalData? <StandardTable data={ animalData || [] } skip={['uid','gid','ID']} replaceKeys={repKeys} replaceValues={ repValues } /> : null }
            {/* <div className="align-middle pt-5">
                <Button
                    disabled={id === ''}
                    variant="outlined"
                    className="corp"
                    onClick={ clearSearch }
                >
                    <ButtonDelete text={__('Clear', 'clear')} />
                </Button>
            </div> */}
        </div>
    );
};

export default ExplorerPageSingleCidComponent;